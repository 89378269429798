export interface TicketTag {
  _id: string
  description: string
  color?: string
  organization?: string
}

export const useTicketTagStore = defineStore(`ticket-tag`, () => {
  /** @deprecated */
  const ticketTags = ref<Array<TicketTag>>([])

  const getTicketTags = async (pagination = false, organization?: string) =>
    useCrudMethods<TicketTag>("/ticket-tags", ticketTags).readItems({
      query: JSON.stringify({ organization }),
      pagination,
      sort: JSON.stringify({ description: "asc" }),
    })
  const updateTicketTag = async (id: TicketTag["_id"], item: Partial<TicketTag>) =>
    useCrudMethods<TicketTag>("/ticket-tags", ticketTags).updateItem(id, item)
  const createTicketTag = async (item: Partial<TicketTag>) => useCrudMethods<TicketTag>("/ticket-tags", ticketTags).createItem(item)
  const deleteTicketTag = async (id: TicketTag["_id"]) => useCrudMethods<TicketTag>("/ticket-tags", ticketTags).deleteItem(id)

  const getTicketTagCount = async (organization?: string): Promise<Array<CountAggregate>> => {
    const { data } = await useSamApi<Array<CountAggregate>>(
      `/ticket-tags/ticket-count${organization ? `?organization=${organization}` : ""}`,
    )
    return data
  }

  return {
    ticketTags,

    getTicketTags,
    updateTicketTag,
    createTicketTag,
    deleteTicketTag,
    getTicketTagCount,
  }
})
